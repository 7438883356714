import React, { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Users, Trash2, AlertCircle } from 'lucide-react';
import { db } from '../db/database';
import DashboardLayout from '../components/DashboardLayout';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AdminDashboard() {
  const { isAdmin } = useAuth();
  const navigate = useNavigate();
  const [deleteStatus, setDeleteStatus] = useState<string>('');
  const [error, setError] = useState<string>('');

  const users = useLiveQuery(() => db.users.toArray());

  React.useEffect(() => {
    if (!isAdmin) {
      navigate('/dashboard');
    }
  }, [isAdmin, navigate]);

  const handleDeleteUser = async (email: string) => {
    try {
      await db.users.where('email').equals(email).delete();
      setDeleteStatus(`Bruger ${email} blev slettet`);
      setError('');
    } catch (err) {
      setError('Der opstod en fejl under sletning af bruger');
      console.error('Error deleting user:', err);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Admin Dashboard</h1>

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-800 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        {deleteStatus && (
          <div className="mb-4 p-4 bg-green-100 text-green-800 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {deleteStatus}
          </div>
        )}

        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold">Registrerede Brugere</h2>
            <Users className="h-6 w-6 text-blue-600" />
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Klub
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Oprettet
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Handlinger
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users?.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{user.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.clubName}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(user.createdAt).toLocaleDateString('da-DK')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      {!user.isAdmin && (
                        <button
                          onClick={() => handleDeleteUser(user.email)}
                          className="text-red-600 hover:text-red-900 flex items-center justify-end ml-auto"
                        >
                          <Trash2 className="h-4 w-4 mr-1" />
                          Slet
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(!users || users.length === 0) && (
              <div className="text-center py-8">
                <Users className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">Ingen brugere fundet</h3>
                <p className="mt-1 text-sm text-gray-500">Der er endnu ingen registrerede brugere i systemet.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}