import React from 'react';
import { Shield, Users, Edit } from 'lucide-react';
import type { Team } from '../db/database';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../db/database';

interface TeamCardProps {
  team: Team;
  onEdit: () => void;
  onClick: () => void;
}

export default function TeamCard({ team, onEdit, onClick }: TeamCardProps) {
  const memberCount = useLiveQuery(
    () => db.members.where('teamId').equals(team.id!).count()
  ) ?? 0;

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 hover:shadow-xl transition">
      <div className="flex justify-between items-start mb-4">
        <div className="flex items-center space-x-4">
          {team.logo ? (
            <img src={team.logo} alt={team.name} className="w-16 h-16 object-contain rounded-lg" />
          ) : (
            <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center">
              <Shield className="w-8 h-8 text-blue-600" />
            </div>
          )}
          <div>
            <h3 className="text-xl font-bold text-gray-900">{team.name}</h3>
            <p className="text-gray-600">{team.league}</p>
            <p className="text-sm text-gray-500">Grundlagt {team.founded}</p>
          </div>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
        >
          <Edit className="w-4 h-4 text-gray-500" />
        </button>
      </div>

      <div className="flex items-center justify-between pt-4 border-t border-gray-100">
        <div className="flex items-center text-gray-600">
          <Users className="w-5 h-5 mr-2" />
          <span>{memberCount} medlemmer</span>
        </div>
        <button
          onClick={onClick}
          className="text-blue-600 hover:text-blue-700 text-sm font-medium"
        >
          Se detaljer
        </button>
      </div>
    </div>
  );
}