import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Mail, Phone, MapPin } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-400">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Logo and Description */}
          <div className="space-y-4 text-center md:text-left">
            <div className="flex items-center justify-center md:justify-start">
              <Trophy className="h-6 w-6 text-blue-500 mr-2" />
              <span className="text-white font-bold text-xl">Fodboldsystem</span>
            </div>
            <p className="text-sm">
              Professionel klubadministration for danske fodboldklubber.
            </p>
          </div>

          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="text-white font-semibold mb-4">Hurtige Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-white transition-colors">
                  Forside
                </Link>
              </li>
              <li>
                <Link to="/om" className="hover:text-white transition-colors">
                  Om Os
                </Link>
              </li>
              <li>
                <Link to="/register" className="hover:text-white transition-colors">
                  Opret Forening
                </Link>
              </li>
              <li>
                <Link to="/login" className="hover:text-white transition-colors">
                  Log Ind
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div className="text-center md:text-left">
            <h3 className="text-white font-semibold mb-4">Kontakt</h3>
            <ul className="space-y-2">
              <li className="flex items-center justify-center md:justify-start">
                <Mail className="h-4 w-4 mr-2" />
                <a
                  href="mailto:support@fodboldsystem.dk"
                  className="hover:text-white transition-colors"
                >
                  support@fodboldsystem.dk
                </a>
              </li>
              <li className="flex items-center justify-center md:justify-start">
                <Phone className="h-4 w-4 mr-2" />
                <span>+45 12 34 56 78</span>
              </li>
              <li className="flex items-center justify-center md:justify-start">
                <MapPin className="h-4 w-4 mr-2" />
                <span>Eksempelvej 123, 1234 København</span>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className="text-center md:text-left">
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy" className="hover:text-white transition-colors">
                  Privatlivspolitik
                </Link>
              </li>
              <li>
                <Link to="/terms" className="hover:text-white transition-colors">
                  Vilkår og betingelser
                </Link>
              </li>
              <li>
                <span>CVR: 12345678</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-center">
          <p>© {new Date().getFullYear()} Fodboldsystem. Alle rettigheder forbeholdes.</p>
        </div>
      </div>
    </footer>
  );
}