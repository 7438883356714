import React, { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { 
  CalendarClock, Plus, Users, MapPin, Clock, 
  ChevronLeft, ChevronRight, Trash2 
} from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import { db } from '../db/database';
import { format, addDays, startOfWeek, isSameDay } from 'date-fns';
import { da } from 'date-fns/locale';

export default function Training() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAddModal, setShowAddModal] = useState(false);

  const teams = useLiveQuery(() => db.teams.toArray());
  const trainingSessions = useLiveQuery(() => db.trainingSessions.toArray());

  const weekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  const handlePrevWeek = () => {
    setSelectedDate(prev => addDays(prev, -7));
  };

  const handleNextWeek = () => {
    setSelectedDate(prev => addDays(prev, 7));
  };

  const handleAddTraining = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);

    const trainingData = {
      teamId: Number(formData.get('teamId')),
      dayOfWeek: Number(formData.get('dayOfWeek')),
      startTime: formData.get('startTime') as string,
      endTime: formData.get('endTime') as string,
      location: formData.get('location') as string
    };

    await db.trainingSessions.add(trainingData);
    setShowAddModal(false);
  };

  const getTrainingsForDay = (date: Date) => {
    if (!trainingSessions) return [];
    return trainingSessions.filter(session => session.dayOfWeek === date.getDay());
  };

  const handleDeleteTraining = async (id: number) => {
    if (window.confirm('Er du sikker på at du vil slette denne træningstid?')) {
      await db.trainingSessions.delete(id);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Træningskalender</h1>
          <button
            onClick={() => setShowAddModal(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700 transition"
          >
            <Plus className="w-5 h-5" />
            Tilføj træningstid
          </button>
        </div>

        {/* Calendar Navigation */}
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="flex items-center justify-between p-4 border-b">
            <button
              onClick={handlePrevWeek}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <h2 className="text-lg font-semibold">
              {format(weekStart, 'MMMM yyyy', { locale: da })}
            </h2>
            <button
              onClick={handleNextWeek}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>

          {/* Calendar Grid */}
          <div className="grid grid-cols-7 gap-px bg-gray-200">
            {weekDays.map((date, index) => (
              <div
                key={index}
                className={`min-h-[200px] bg-white ${
                  isSameDay(date, new Date()) ? 'bg-blue-50' : ''
                }`}
              >
                <div className="p-2 border-b">
                  <div className="text-sm font-medium">
                    {format(date, 'EEEE', { locale: da })}
                  </div>
                  <div className="text-lg">
                    {format(date, 'd', { locale: da })}
                  </div>
                </div>
                <div className="p-2 space-y-2">
                  {getTrainingsForDay(date).map((training) => {
                    const team = teams?.find(t => t.id === training.teamId);
                    return (
                      <div
                        key={training.id}
                        className="bg-blue-100 p-2 rounded text-sm group hover:bg-blue-200 transition-colors"
                      >
                        <div className="flex justify-between items-start">
                          <div className="font-medium">{team?.name}</div>
                          <button
                            onClick={() => training.id && handleDeleteTraining(training.id)}
                            className="opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <Trash2 className="w-4 h-4 text-red-500 hover:text-red-700" />
                          </button>
                        </div>
                        <div className="flex items-center text-xs text-gray-600">
                          <Clock className="w-3 h-3 mr-1" />
                          {training.startTime} - {training.endTime}
                        </div>
                        <div className="flex items-center text-xs text-gray-600">
                          <MapPin className="w-3 h-3 mr-1" />
                          {training.location}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Add Training Modal */}
        {showAddModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h2 className="text-xl font-bold mb-4">Tilføj træningstid</h2>
              <form onSubmit={handleAddTraining} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Hold
                  </label>
                  <select
                    required
                    name="teamId"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Vælg hold</option>
                    {teams?.map(team => (
                      <option key={team.id} value={team.id}>{team.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Ugedag
                  </label>
                  <select
                    required
                    name="dayOfWeek"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="1">Mandag</option>
                    <option value="2">Tirsdag</option>
                    <option value="3">Onsdag</option>
                    <option value="4">Torsdag</option>
                    <option value="5">Fredag</option>
                    <option value="6">Lørdag</option>
                    <option value="0">Søndag</option>
                  </select>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Start tid
                    </label>
                    <input
                      required
                      type="time"
                      name="startTime"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Slut tid
                    </label>
                    <input
                      required
                      type="time"
                      name="endTime"
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lokation
                  </label>
                  <input
                    required
                    type="text"
                    name="location"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="F.eks. Bane 1"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setShowAddModal(false)}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition"
                  >
                    Annuller
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    Tilføj træning
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}