import React, { useState } from 'react';
import { Trash2, AlertCircle, RefreshCw } from 'lucide-react';
import { clearAllData, generateTestData } from '../../utils/generateTestData';
import { useAuth } from '../../context/AuthContext';

export default function DataSettings() {
  const { user } = useAuth();
  const [status, setStatus] = useState<{
    message: string;
    type: 'success' | 'error' | '';
  }>({ message: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleClearData = async () => {
    if (window.confirm('Er du sikker på at du vil slette alle data? Dette kan ikke fortrydes.')) {
      setIsLoading(true);
      try {
        const success = await clearAllData();
        if (success) {
          setStatus({
            message: 'Alle data er blevet slettet',
            type: 'success'
          });
        } else {
          throw new Error('Kunne ikke slette data');
        }
      } catch (error) {
        setStatus({
          message: 'Der opstod en fejl under sletning af data',
          type: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGenerateTestData = async () => {
    if (!user?.email) return;
    
    setIsLoading(true);
    try {
      const success = await generateTestData(user.email);
      if (success) {
        setStatus({
          message: 'Test data er blevet genereret',
          type: 'success'
        });
      } else {
        throw new Error('Kunne ikke generere test data');
      }
    } catch (error) {
      setStatus({
        message: 'Der opstod en fejl under generering af test data',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {status.message && (
        <div className={`mb-6 p-4 rounded-lg flex items-center ${
          status.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          <AlertCircle className="h-5 w-5 mr-2" />
          {status.message}
        </div>
      )}

      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-semibold mb-6">Data Administration</h2>
        
        <div className="space-y-6">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <h3 className="font-medium text-gray-900">Generer Test Data</h3>
              <p className="text-sm text-gray-500">
                Opret eksempel data for at teste systemet
              </p>
            </div>
            <button
              onClick={handleGenerateTestData}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition disabled:opacity-50"
            >
              <RefreshCw className={`h-5 w-5 mr-2 ${isLoading ? 'animate-spin' : ''}`} />
              {isLoading ? 'Genererer...' : 'Generer Data'}
            </button>
          </div>

          <div className="flex items-center justify-between p-4 bg-red-50 rounded-lg">
            <div>
              <h3 className="font-medium text-gray-900">Ryd Alle Data</h3>
              <p className="text-sm text-gray-500">
                Slet alle data i systemet. Dette kan ikke fortrydes.
              </p>
            </div>
            <button
              onClick={handleClearData}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition disabled:opacity-50"
            >
              <Trash2 className="h-5 w-5 mr-2" />
              {isLoading ? 'Sletter...' : 'Ryd Data'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}