import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Trophy, Users, Calendar, BarChart3, Settings, LogOut,
  Menu, X, ChevronDown, Building2, User, CalendarClock
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  const menuItems = [
    {
      path: '/dashboard',
      icon: Building2,
      label: 'Dashboard'
    },
    {
      path: '/members',
      icon: Users,
      label: 'Medlemmer'
    },
    {
      path: '/teams',
      icon: Trophy,
      label: 'Hold'
    },
    {
      path: '/training',
      icon: CalendarClock,
      label: 'Træning'
    },
    {
      path: '/settings',
      icon: Settings,
      label: 'Indstillinger'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Top Navigation Bar */}
      <nav className="fixed top-0 left-0 right-0 bg-gradient-to-br from-blue-900 to-blue-700 z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            {/* Logo and Mobile Menu Button */}
            <div className="flex items-center">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-blue-100 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300 lg:hidden"
                aria-label="Open menu"
              >
                {isMobileMenuOpen ? (
                  <X className="h-6 w-6" />
                ) : (
                  <Menu className="h-6 w-6" />
                )}
              </button>
              <Link to="/dashboard" className="flex items-center ml-2 lg:ml-0">
                <Trophy className="h-8 w-8 text-white" />
                <span className="ml-2 text-xl font-bold text-white hidden sm:block">Fodboldsystem</span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden lg:flex lg:items-center lg:space-x-4">
              {menuItems.map((item) => {
                const Icon = item.icon;
                return (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                      isActive(item.path)
                        ? 'bg-blue-800 text-white'
                        : 'text-blue-100 hover:text-white hover:bg-blue-800'
                    }`}
                  >
                    <Icon className="h-5 w-5 mr-2" />
                    <span>{item.label}</span>
                  </Link>
                );
              })}
            </div>

            {/* Profile Dropdown */}
            <div className="flex items-center">
              <div className="relative">
                <button
                  onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
                  className="flex items-center space-x-3 px-3 py-2 rounded-md text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  <div className="w-8 h-8 rounded-full bg-blue-800 flex items-center justify-center">
                    <User className="h-5 w-5 text-white" />
                  </div>
                  <div className="hidden md:block text-left">
                    <p className="text-sm font-medium text-white truncate max-w-[150px]">{user?.clubName}</p>
                    <p className="text-xs text-blue-200 truncate max-w-[150px]">{user?.email}</p>
                  </div>
                  <ChevronDown className="h-5 w-5 text-blue-200 hidden md:block" />
                </button>

                {isProfileDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Link
                        to="/settings"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsProfileDropdownOpen(false)}
                      >
                        <div className="flex items-center">
                          <Settings className="h-4 w-4 mr-2" />
                          Indstillinger
                        </div>
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-full text-left block px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                      >
                        <div className="flex items-center">
                          <LogOut className="h-4 w-4 mr-2" />
                          Log ud
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden fixed inset-0 z-40">
          <div className="fixed inset-0 bg-black bg-opacity-25" onClick={() => setIsMobileMenuOpen(false)} />
          <div className="fixed inset-y-0 left-0 w-64 bg-blue-900 shadow-lg transform transition-transform duration-300">
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between h-16 px-4 border-b border-blue-800">
                <Link to="/dashboard" className="flex items-center" onClick={() => setIsMobileMenuOpen(false)}>
                  <Trophy className="h-8 w-8 text-white" />
                  <span className="ml-2 text-xl font-bold text-white">Fodboldsystem</span>
                </Link>
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="rounded-md text-white hover:text-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <div className="flex-1 overflow-y-auto">
                <div className="px-2 py-4 space-y-1">
                  {menuItems.map((item) => {
                    const Icon = item.icon;
                    return (
                      <Link
                        key={item.path}
                        to={item.path}
                        className={`flex items-center px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                          isActive(item.path)
                            ? 'bg-blue-800 text-white'
                            : 'text-blue-100 hover:text-white hover:bg-blue-800'
                        }`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        <Icon className="h-5 w-5 mr-2" />
                        <span>{item.label}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
              <div className="border-t border-blue-800 p-4">
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-3 py-2 text-sm font-medium text-white hover:bg-blue-800 rounded-md"
                >
                  <LogOut className="h-5 w-5 mr-2" />
                  Log ud
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="pt-16">
        {children}
      </main>
    </div>
  );
}