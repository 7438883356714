import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import DashboardLayout from '../../components/DashboardLayout';
import { Settings as SettingsIcon, CreditCard, Database } from 'lucide-react';

export default function Settings() {
  const location = useLocation();
  
  const tabs = [
    {
      name: 'Data',
      path: '/settings',
      icon: Database,
      exact: true
    },
    {
      name: 'Abonnement',
      path: '/settings/subscription',
      icon: CreditCard
    }
  ];

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Indstillinger</h1>
        </div>

        {/* Navigation Tabs */}
        <div className="mb-8 border-b border-gray-200">
          <nav className="flex space-x-8">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              const isActive = tab.exact 
                ? location.pathname === tab.path
                : location.pathname.startsWith(tab.path);
              
              return (
                <Link
                  key={tab.path}
                  to={tab.path}
                  className={`
                    flex items-center px-1 py-4 text-sm font-medium border-b-2 
                    ${isActive 
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }
                  `}
                >
                  <Icon className="w-5 h-5 mr-2" />
                  {tab.name}
                </Link>
              );
            })}
          </nav>
        </div>

        {/* Content */}
        <Outlet />
      </div>
    </DashboardLayout>
  );
}