import React from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Users, Shield, X } from 'lucide-react';
import { db } from '../db/database';
import type { Team } from '../db/database';
import { format } from 'date-fns';

interface TeamDetailsProps {
  team: Team;
  onClose: () => void;
}

export default function TeamDetails({ team, onClose }: TeamDetailsProps) {
  const members = useLiveQuery(
    () => db.members.where('teamId').equals(team.id!).toArray()
  );

  const trainingSessions = useLiveQuery(
    () => db.trainingSessions.where('teamId').equals(team.id!).toArray()
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        {/* Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              {team.logo ? (
                <img src={team.logo} alt={team.name} className="w-16 h-16 object-contain rounded-lg mr-4" />
              ) : (
                <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center mr-4">
                  <Shield className="w-8 h-8 text-blue-600" />
                </div>
              )}
              <div>
                <h2 className="text-2xl font-bold text-gray-900">{team.name}</h2>
                <p className="text-gray-500">{team.league} • Grundlagt {team.founded}</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Training Schedule */}
        <div className="p-6 border-b border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Træningstider</h3>
          <div className="grid gap-4">
            {trainingSessions?.map((session) => (
              <div key={session.id} className="bg-gray-50 p-4 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">
                      {['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'][session.dayOfWeek]}
                    </p>
                    <p className="text-gray-600">
                      {session.startTime} - {session.endTime}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-gray-500">{session.location}</p>
                  </div>
                </div>
              </div>
            ))}
            {(!trainingSessions || trainingSessions.length === 0) && (
              <p className="text-gray-500 text-center py-4">Ingen træningstider registreret</p>
            )}
          </div>
        </div>

        {/* Members List */}
        <div className="p-6">
          <h3 className="text-lg font-semibold mb-4">Holdmedlemmer</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Navn
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Medlem siden
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members?.map((member) => (
                  <tr key={member.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <Users className="h-5 w-5 text-gray-400 mr-3" />
                        <div className="text-sm font-medium text-gray-900">
                          {member.name}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{member.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                        {member.memberType}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {format(new Date(member.memberSince), 'dd/MM/yyyy')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        member.status === 'active' 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {member.status === 'active' ? 'Aktiv' : 'Inaktiv'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {(!members || members.length === 0) && (
              <div className="text-center py-12">
                <Users className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">Ingen medlemmer</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Der er endnu ingen medlemmer på dette hold.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}