import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, ArrowRight } from 'lucide-react';
import MainNav from '../components/MainNav';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import TopBar from '../components/TopBar';
import '../styles/animations.css';

export default function LandingPage() {
  return (
    <div className="min-h-screen">
      <TopBar />
      <MainNav />
      
      {/* Hero Section */}
      <header className="relative overflow-hidden bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 pt-16">
        <div 
          className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1522778526097-ce0a22ceb253?q=80&w=2070')] bg-cover bg-center opacity-25"
          style={{ animation: '20s ease-out 0s infinite alternate none running kenBurns' }}
        />
        <div className="relative z-10 max-w-7xl mx-auto px-4 py-24">
          <div className="max-w-3xl mx-auto text-center">
            <Trophy className="h-16 w-16 text-white mx-auto mb-6" />
            <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Professionel Klubadministration
            </h1>
            <div className="text-xl text-blue-100">
              <p className="typing-animation">
                Medlemsadministration, økonomi og kommunikation samlet ét sted.
              </p>
            </div>
            <div className="mt-10 flex flex-col sm:flex-row gap-4 justify-center">
              <Link to="/register" className="btn-primary">
                Start Gratis <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
              <button 
                onClick={() => document.getElementById('mission')?.scrollIntoView({ behavior: 'smooth' })}
                className="btn-secondary"
              >
                Læs Mere <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <Features />

      {/* About Section */}
      <section id="mission" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Vi digitaliserer dansk fodbold
            </h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Fodboldsystem er skabt af fodboldentusiaster for at gøre klubadministration nemmere og mere effektiv.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <Pricing />

      {/* Call to Action */}
      <CallToAction />

      {/* Footer */}
      <Footer />
    </div>
  );
}