import React from 'react';
import PricingCard from './PricingCard';

const plans = [
  {
    title: 'Gratis',
    price: 0,
    features: [
      'Op til 25 medlemmer',
      'Grundlæggende medlemsadministration',
      'Kamp- og træningskalender',
      'Email support'
    ]
  },
  {
    title: 'Standard',
    price: 499,
    features: [
      'Op til 100 medlemmer',
      'Holdstyring',
      'Betalingshåndtering',
      'Økonomistyring',
      'Prioriteret support'
    ]
  },
  {
    title: 'Premium',
    price: 999,
    features: [
      'Ubegrænset antal medlemmer',
      'Avanceret økonomistyring',
      'Frivilligstyring',
      'Integration med DBU systemer',
      'Premium support'
    ]
  }
];

export default function Pricing() {
  const handleSelect = (plan: string) => {
    console.log(`Selected plan: ${plan}`);
    // Handle plan selection
  };

  return (
    <section id="pricing" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Vælg den rigtige pakke til din klub
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Fleksible løsninger der passer til alle klubstørrelser
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <PricingCard
              key={plan.title}
              title={plan.title}
              price={plan.price}
              features={plan.features}
              onSelect={() => handleSelect(plan.title)}
            />
          ))}
        </div>
      </div>
    </section>
  );
}