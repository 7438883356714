import React, { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { 
  Users, Search, Plus, UserCircle, Mail, Phone, 
  MapPin, Calendar, Tag, Clock, FileText, 
  CreditCard, AlertCircle, CheckCircle, XCircle
} from 'lucide-react';
import { format } from 'date-fns';
import { db } from '../db/database';
import DashboardLayout from '../components/DashboardLayout';
import type { Member, PaymentRecord } from '../db/database';

export default function Members() {
  const [search, setSearch] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [view, setView] = useState<'all' | 'overdue'>('all');

  const members = useLiveQuery(
    () => db.members
      .filter(member => 
        member.name.toLowerCase().includes(search.toLowerCase()) ||
        member.email.toLowerCase().includes(search.toLowerCase()) ||
        member.memberType.toLowerCase().includes(search.toLowerCase())
      )
      .toArray()
  );

  const teams = useLiveQuery(() => db.teams.toArray());
  const financialSummary = useLiveQuery(() => db.getFinancialSummary());

  const handlePayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedMember) return;

    const form = e.currentTarget;
    const formData = new FormData(form);

    const paymentData: Omit<PaymentRecord, 'id'> = {
      memberId: selectedMember.id!,
      amount: Number(formData.get('amount')),
      date: new Date(),
      type: formData.get('type') as 'membership' | 'training' | 'other',
      status: 'completed',
      description: formData.get('description') as string
    };

    await db.payments.add(paymentData);
    await db.members.update(selectedMember.id!, {
      paymentStatus: 'paid',
      lastPaymentDate: new Date()
    });

    setShowPaymentModal(false);
    setSelectedMember(null);
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Financial Overview */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-xl shadow-lg p-6 border-t-4 border-blue-500">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Total Indtægt</h3>
              <CreditCard className="h-6 w-6 text-blue-500" />
            </div>
            <p className="text-3xl font-bold">{financialSummary?.totalRevenue.toLocaleString()} kr</p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6 border-t-4 border-green-500">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Betalende Medlemmer</h3>
              <CheckCircle className="h-6 w-6 text-green-500" />
            </div>
            <p className="text-3xl font-bold">{financialSummary?.paidMembers || 0}</p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6 border-t-4 border-red-500">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Manglende Betaling</h3>
              <AlertCircle className="h-6 w-6 text-red-500" />
            </div>
            <p className="text-3xl font-bold">{financialSummary?.overdueMembers || 0}</p>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-6 border-t-4 border-purple-500">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">Gns. Kontingent</h3>
              <CreditCard className="h-6 w-6 text-purple-500" />
            </div>
            <p className="text-3xl font-bold">
              {Math.round(financialSummary?.averageMembershipFee || 0).toLocaleString()} kr
            </p>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div>
                <h2 className="text-xl font-bold text-gray-900">Medlemmer</h2>
                <p className="text-sm text-gray-500">Administrer klubbens medlemmer og kontingenter</p>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex gap-2">
                  <button
                    onClick={() => setView('all')}
                    className={`px-4 py-2 rounded-lg ${
                      view === 'all' 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    Alle
                  </button>
                  <button
                    onClick={() => setView('overdue')}
                    className={`px-4 py-2 rounded-lg ${
                      view === 'overdue' 
                        ? 'bg-red-600 text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    Manglende Betaling
                  </button>
                </div>
                <button
                  onClick={() => setShowAddModal(true)}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700 transition"
                >
                  <Plus className="w-5 h-5" />
                  Tilføj medlem
                </button>
              </div>
            </div>
          </div>

          <div className="p-6">
            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Søg efter navn, email eller medlemstype..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Medlem
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Kontakt
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Betalingsstatus
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sidste Betaling
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Handlinger
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {members?.map((member) => (
                    <tr key={member.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <UserCircle className="h-10 w-10 text-gray-400" />
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">{member.name}</div>
                            <div className="text-sm text-gray-500">
                              Medlem siden: {format(new Date(member.memberSince), 'dd/MM/yyyy')}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">{member.email}</div>
                        <div className="text-sm text-gray-500">{member.phone}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                          {member.memberType}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          member.paymentStatus === 'paid' 
                            ? 'bg-green-100 text-green-800'
                            : member.paymentStatus === 'pending'
                            ? 'bg-yellow-100 text-yellow-800'
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {member.paymentStatus === 'paid' ? 'Betalt' : 
                           member.paymentStatus === 'pending' ? 'Afventer' : 
                           'Mangler betaling'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {member.lastPaymentDate 
                          ? format(new Date(member.lastPaymentDate), 'dd/MM/yyyy')
                          : 'Ingen betalinger'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => {
                            setSelectedMember(member);
                            setShowPaymentModal(true);
                          }}
                          className="text-blue-600 hover:text-blue-900 mr-4"
                        >
                          Registrer betaling
                        </button>
                        <button
                          onClick={() => {
                            setSelectedMember(member);
                            setShowAddModal(true);
                          }}
                          className="text-gray-600 hover:text-gray-900"
                        >
                          Rediger
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {(!members || members.length === 0) && (
                <div className="text-center py-12">
                  <UserCircle className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">Ingen medlemmer fundet</h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Kom i gang ved at tilføje dit første medlem.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Payment Modal */}
        {showPaymentModal && selectedMember && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h2 className="text-xl font-bold mb-4">Registrer Betaling</h2>
              <p className="text-gray-600 mb-4">
                Registrer betaling for {selectedMember.name}
              </p>
              <form onSubmit={handlePayment} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Beløb
                  </label>
                  <input
                    required
                    type="number"
                    name="amount"
                    min="0"
                    step="50"
                    defaultValue={selectedMember.membershipFee}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Type
                  </label>
                  <select
                    required
                    name="type"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="membership">Kontingent</option>
                    <option value="training">Træningsgebyr</option>
                    <option value="other">Andet</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Beskrivelse
                  </label>
                  <textarea
                    name="description"
                    rows={3}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowPaymentModal(false);
                      setSelectedMember(null);
                    }}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition"
                  >
                    Annuller
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    Registrer Betaling
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Add/Edit Member Modal */}
        {showAddModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-2xl w-full p-6 max-h-[90vh] overflow-y-auto">
              <h2 className="text-xl font-bold mb-4">
                {selectedMember ? 'Rediger medlem' : 'Tilføj nyt medlem'}
              </h2>
              <form onSubmit={async (e) => {
                e.preventDefault();
                const form = e.currentTarget;
                const formData = new FormData(form);

                const memberData = {
                  name: formData.get('name') as string,
                  email: formData.get('email') as string,
                  phone: formData.get('phone') as string,
                  address: formData.get('address') as string,
                  birthDate: new Date(formData.get('birthDate') as string),
                  memberType: formData.get('memberType') as 'active' | 'passive' | 'youth' | 'senior',
                  memberSince: new Date(),
                  status: 'active' as const,
                  teamId: Number(formData.get('teamId')) || undefined,
                  notes: formData.get('notes') as string,
                  paymentStatus: 'pending' as const,
                  membershipFee: Number(formData.get('membershipFee'))
                };

                if (selectedMember?.id) {
                  await db.members.update(selectedMember.id, memberData);
                } else {
                  await db.members.add(memberData);
                }

                setShowAddModal(false);
                setSelectedMember(null);
              }} className="space-y-4">
                {/* Existing member form fields... */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Navn
                    </label>
                    <input
                      required
                      type="text"
                      name="name"
                      defaultValue={selectedMember?.name}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      defaultValue={selectedMember?.email}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Telefon
                    </label>
                    <input
                      required
                      type="tel"
                      name="phone"
                      defaultValue={selectedMember?.phone}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Adresse
                    </label>
                    <input
                      required
                      type="text"
                      name="address"
                      defaultValue={selectedMember?.address}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Fødselsdato
                    </label>
                    <input
                      required
                      type="date"
                      name="birthDate"
                      defaultValue={selectedMember?.birthDate.toISOString().split('T')[0]}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Medlemstype
                    </label>
                    <select
                      required
                      name="memberType"
                      defaultValue={selectedMember?.memberType}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="youth">Ungdom</option>
                      <option value="senior">Senior</option>
                      <option value="passive">Passiv</option>
                      <option value="active">Aktiv</option>
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Hold
                    </label>
                    <select
                      name="teamId"
                      defaultValue={selectedMember?.teamId}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Vælg hold</option>
                      {teams?.map(team => (
                        <option key={team.id} value={team.id}>{team.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Kontingent (kr/halvår)
                    </label>
                    <input
                      required
                      type="number"
                      name="membershipFee"
                      min="0"
                      step="50"
                      defaultValue={selectedMember?.membershipFee || 600}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Noter
                  </label>
                  <textarea
                    name="notes"
                    rows={3}
                    defaultValue={selectedMember?.notes}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddModal(false);
                      setSelectedMember(null);
                    }}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition"
                  >
                    Annuller
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    {selectedMember ? 'Gem ændringer' : 'Tilføj medlem'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}