import React from 'react';
import { CheckCircle, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface PricingCardProps {
  title: string;
  price: number;
  features: string[];
  recommended?: boolean;
}

export default function PricingCard({ title, price, features, recommended }: PricingCardProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleSelect = () => {
    if (isAuthenticated) {
      navigate('/settings/subscription', { 
        state: { selectedPlan: { title, price } }
      });
    } else {
      navigate('/register', { 
        state: { selectedPlan: { title, price } }
      });
    }
  };

  return (
    <div className={`bg-white rounded-lg shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105 ${
      recommended ? 'ring-2 ring-blue-500 ring-offset-2' : ''
    }`}>
      {recommended && (
        <div className="bg-blue-500 text-white text-center py-2 text-sm font-medium">
          Anbefalet
        </div>
      )}
      <div className="p-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-4">{title}</h3>
        <div className="flex items-baseline mb-8">
          <span className="text-4xl font-bold">{price}</span>
          <span className="text-gray-600 ml-2">kr/md</span>
        </div>
        <ul className="space-y-4 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
        <button
          onClick={handleSelect}
          className="w-full bg-blue-600 text-white rounded-lg px-4 py-3 hover:bg-blue-700 transition-colors flex items-center justify-center"
        >
          {isAuthenticated ? 'Vælg abonnement' : 'Start gratis prøveperiode'}
          <ArrowRight className="ml-2 h-5 w-5" />
        </button>
      </div>
    </div>
  );
}