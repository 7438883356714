import React, { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { Plus, Search, Shield } from 'lucide-react';
import { db } from '../db/database';
import TeamCard from '../components/TeamCard';
import TeamDetails from '../components/TeamDetails';
import DashboardLayout from '../components/DashboardLayout';
import type { Team } from '../db/database';

export default function Teams() {
  const [search, setSearch] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [editingTeam, setEditingTeam] = useState<Team | null>(null);

  const teams = useLiveQuery(
    () => db.teams
      .filter(team => 
        team.name.toLowerCase().includes(search.toLowerCase()) ||
        team.league.toLowerCase().includes(search.toLowerCase())
      )
      .toArray()
  );

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Hold</h1>
          <button
            onClick={() => setShowAddModal(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700 transition"
          >
            <Plus className="w-5 h-5" />
            Opret nyt hold
          </button>
        </div>

        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Søg efter hold eller liga..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {teams?.map(team => (
            <TeamCard 
              key={team.id} 
              team={team}
              onEdit={() => setEditingTeam(team)}
              onClick={() => setSelectedTeam(team)}
            />
          ))}
          {(!teams || teams.length === 0) && (
            <div className="col-span-full text-center py-12">
              <Shield className="w-16 h-16 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-1">Ingen hold fundet</h3>
              <p className="text-gray-500">Opret et nyt hold for at komme i gang</p>
            </div>
          )}
        </div>

        {/* Add/Edit Team Modal */}
        {(showAddModal || editingTeam) && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h2 className="text-xl font-bold mb-4">
                {editingTeam ? 'Rediger hold' : 'Opret nyt hold'}
              </h2>
              <form onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);
                const teamData = {
                  name: formData.get('name') as string,
                  league: formData.get('league') as string,
                  founded: Number(formData.get('founded')),
                  logo: formData.get('logo') as string || undefined
                };

                if (editingTeam?.id) {
                  await db.teams.update(editingTeam.id, teamData);
                } else {
                  await db.teams.add(teamData);
                }

                setShowAddModal(false);
                setEditingTeam(null);
              }}>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Holdnavn
                    </label>
                    <input
                      required
                      type="text"
                      name="name"
                      defaultValue={editingTeam?.name}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Liga/Række
                    </label>
                    <input
                      required
                      type="text"
                      name="league"
                      defaultValue={editingTeam?.league}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Grundlagt år
                    </label>
                    <input
                      required
                      type="number"
                      name="founded"
                      min="1800"
                      max={new Date().getFullYear()}
                      defaultValue={editingTeam?.founded}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Logo URL (valgfrit)
                    </label>
                    <input
                      type="url"
                      name="logo"
                      defaultValue={editingTeam?.logo}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddModal(false);
                      setEditingTeam(null);
                    }}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition"
                  >
                    Annuller
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    {editingTeam ? 'Gem ændringer' : 'Opret hold'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Team Details Modal */}
        {selectedTeam && (
          <TeamDetails
            team={selectedTeam}
            onClose={() => setSelectedTeam(null)}
          />
        )}
      </div>
    </DashboardLayout>
  );
}