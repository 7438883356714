import React from 'react';
import MainNav from '../components/MainNav';
import Footer from '../components/Footer';
import { Heart, Shield, Star, CheckCircle, Building2, Mail, Code, Trophy } from 'lucide-react';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-100">
      <MainNav />
      
      <div className="pt-16">
        <div className="min-h-screen">
          {/* Hero Section */}
          <header className="bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 relative overflow-hidden py-20">
            <div 
              className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1522778526097-ce0a22ceb253?q=80&w=2070')] bg-cover bg-center mix-blend-overlay opacity-10"
            />
            <div className="max-w-7xl mx-auto px-4 relative z-10 text-center">
              <Trophy className="h-16 w-16 text-white mx-auto mb-6" />
              <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
                Vi digitaliserer dansk fodbold
              </h1>
              <p className="text-xl text-blue-100 max-w-2xl mx-auto">
                Fodboldsystem er skabt af fodboldentusiaster for at gøre klubadministration nemmere og mere effektiv.
              </p>
            </div>
          </header>

          {/* Mission Section */}
          <section id="mission" className="py-20 bg-white scroll-mt-16">
            <div className="max-w-7xl mx-auto px-4">
              <div className="grid md:grid-cols-2 gap-12 items-center">
                <div>
                  <h2 className="text-3xl font-bold text-gray-900 mb-6">Vores Mission</h2>
                  <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                    Vi har en vision om at revolutionere måden, danske fodboldklubber administreres på. Ved at kombinere moderne teknologi med dyb forståelse for klubbernes behov, skaber vi løsninger der sparer tid, reducerer administrative byrder og forbedrer kommunikationen mellem alle involverede parter.
                  </p>
                  <div className="space-y-4">
                    {[
                      {
                        title: 'Effektivisering',
                        description: 'Automatisering af rutineopgaver og medlemshåndtering'
                      },
                      {
                        title: 'Digitalisering',
                        description: 'Moderne løsninger til traditionel klubadministration'
                      },
                      {
                        title: 'Fællesskab',
                        description: 'Styrket kommunikation mellem klub, trænere og medlemmer'
                      }
                    ].map((item, index) => (
                      <div key={index} className="flex items-start">
                        <CheckCircle className="h-6 w-6 text-green-500 mr-3 mt-1" />
                        <div>
                          <h3 className="font-semibold text-gray-900">{item.title}</h3>
                          <p className="text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="relative">
                  <img
                    src="https://images.unsplash.com/photo-1606925797300-0b35e9d1794e?q=80&w=2012"
                    alt="Fodboldklub administration"
                    className="rounded-lg shadow-xl"
                  />
                  <div className="absolute -bottom-6 -right-6 bg-blue-600 text-white p-6 rounded-lg shadow-lg">
                    <p className="text-3xl font-bold">200+</p>
                    <p className="text-sm">Tilfredse klubber</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Values Section */}
          <section id="values" className="py-20 bg-gray-50 scroll-mt-16">
            <div className="max-w-7xl mx-auto px-4">
              <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Vores Værdier</h2>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                  {
                    icon: Heart,
                    title: 'Passion',
                    description: 'Vi elsker fodbold og teknologi, og vi brænder for at skabe løsninger der gør en reel forskel for danske fodboldklubber.',
                    color: 'text-red-500'
                  },
                  {
                    icon: Shield,
                    title: 'Integritet',
                    description: 'Vi værner om klubbernes data og sikrer gennemsigtighed i alt hvad vi gør. Tillid er fundamentet for vores samarbejde.',
                    color: 'text-blue-500'
                  },
                  {
                    icon: Star,
                    title: 'Innovation',
                    description: 'Vi udvikler konstant nye features og forbedringer baseret på feedback fra vores brugere og den nyeste teknologi.',
                    color: 'text-yellow-500'
                  }
                ].map((value, index) => {
                  const Icon = value.icon;
                  return (
                    <div key={index} className="bg-white p-8 rounded-xl shadow-md">
                      <Icon className={`h-12 w-12 ${value.color} mb-6`} />
                      <h3 className="text-xl font-bold text-gray-900 mb-4">{value.title}</h3>
                      <p className="text-gray-600">{value.description}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          {/* Team Section */}
          <section id="team" className="py-20 bg-white scroll-mt-16">
            <div className="max-w-7xl mx-auto px-4">
              <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Mød Skaberen</h2>
              <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                  <div className="md:flex">
                    <div className="md:w-1/2">
                      <img
                        src="https://media.licdn.com/dms/image/v2/C4E03AQE-0ZJDjZAXqg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1655469303898?e=1737590400&v=beta&t=4zjsMeKp5H25I11XlIPbhZi7_YFQ0xouB5Tp09Y-yYg"
                        alt="Arslan Afzal"
                        className="w-full h-[500px] object-cover object-center"
                      />
                    </div>
                    <div className="p-8 md:w-1/2">
                      <div className="mb-6">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">Arslan Afzal</h3>
                        <p className="text-blue-600 font-medium">Grundlægger & Udvikler</p>
                      </div>
                      <p className="text-gray-600 mb-6 leading-relaxed">
                        Arslan er en passioneret softwareudvikler med ekspertise i moderne webteknologier. 
                        Med en baggrund i både fodbold og teknologi har han skabt Fodboldsystem for at løse 
                        de reelle udfordringer, som danske fodboldklubber står overfor i deres daglige administration.
                      </p>
                      <div className="border-t border-gray-200 pt-6 space-y-4">
                        <div>
                          <div className="text-sm font-medium text-gray-500">Ekspertise</div>
                          <div className="text-gray-900">Fullstack Udvikling, UX Design, Systemarkitektur</div>
                        </div>
                        <div>
                          <div className="text-sm font-medium text-gray-500">Vision</div>
                          <div className="text-gray-900">
                            At modernisere dansk fodboldadministration gennem innovative digitale løsninger
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Contact Section */}
          <section id="contact" className="py-20 bg-gray-50 scroll-mt-16">
            <div className="max-w-7xl mx-auto px-4">
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="grid md:grid-cols-2">
                  <div className="p-12">
                    <h2 className="text-3xl font-bold text-gray-900 mb-6">Kontakt Os</h2>
                    <p className="text-gray-600 mb-8">
                      Vi er altid klar til at hjælpe dig med spørgsmål om Fodboldsystem.
                    </p>
                    <div className="space-y-6">
                      <div className="flex items-center">
                        <Building2 className="h-6 w-6 text-blue-600 mr-4" />
                        <div>
                          <h3 className="font-semibold text-gray-900">Adresse</h3>
                          <p className="text-gray-600">Eksempelvej 123, 1234 København</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Mail className="h-6 w-6 text-blue-600 mr-4" />
                        <div>
                          <h3 className="font-semibold text-gray-900">Email</h3>
                          <a href="mailto:support@fodboldsystem.dk" className="text-blue-600 hover:text-blue-700">
                            support@fodboldsystem.dk
                          </a>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Code className="h-6 w-6 text-blue-600 mr-4" />
                        <div>
                          <h3 className="font-semibold text-gray-900">CVR</h3>
                          <p className="text-gray-600">12345678</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <img
                      src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=2070"
                      alt="Kontor"
                      className="absolute inset-0 h-full w-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>
    </div>
  );
}