import { db } from '../db/database';

const TEAM_NAMES = [
  'U13 Drenge',
  'U15 Piger',
  'U17 Drenge',
  'U19 Piger',
  'Senior Herrer 1',
  'Senior Herrer 2',
  'Senior Damer',
  'Veteran Hold',
  'Old Boys',
  'Futsal Hold'
];

const LOCATIONS = ['Bane 1', 'Bane 2', 'Kunstgræs', 'Træningsbane', 'Indendørs Hal'];

const MEMBER_TYPES = ['youth', 'senior', 'passive', 'active'] as const;
const MEMBER_STATUSES = ['active', 'inactive'] as const;
const PAYMENT_STATUSES = ['paid', 'pending', 'overdue'] as const;

const DEPARTMENTS = [
  {
    name: 'Ungdomsafdeling',
    budget: 350000,
    expenses: 250000,
    income: 400000,
    description: 'Ungdomsfodbold fra U13 til U19',
    expectedMembers: { min: 40, max: 60 },
    teamTypes: ['U13 Drenge', 'U15 Piger', 'U17 Drenge', 'U19 Piger']
  },
  {
    name: 'Seniorafdeling',
    budget: 500000,
    expenses: 400000,
    income: 600000,
    description: 'Senior herrer og damer',
    expectedMembers: { min: 30, max: 50 },
    teamTypes: ['Senior Herrer 1', 'Senior Herrer 2', 'Senior Damer']
  },
  {
    name: 'Eliteafdeling',
    budget: 750000,
    expenses: 650000,
    income: 900000,
    description: 'Elite og talentudvikling',
    expectedMembers: { min: 25, max: 40 },
    teamTypes: ['U19 Piger', 'Senior Herrer 1', 'Senior Damer']
  },
  {
    name: 'Breddeafdeling',
    budget: 250000,
    expenses: 180000,
    income: 300000,
    description: 'Motionsfodbold og sociale hold',
    expectedMembers: { min: 35, max: 55 },
    teamTypes: ['Veteran Hold', 'Old Boys']
  },
  {
    name: 'Futsal & Indendørs',
    budget: 150000,
    expenses: 100000,
    income: 200000,
    description: 'Indendørs fodbold og futsal aktiviteter',
    expectedMembers: { min: 20, max: 35 },
    teamTypes: ['Futsal Hold']
  }
];

const generateRandomMember = (teamId: number, departmentId: number, teamName: string) => {
  const firstNames = ['Anders', 'Mette', 'Lars', 'Sofie', 'Peter', 'Emma', 'Mikkel', 'Julie', 'Thomas', 'Maria', 
                     'Christian', 'Louise', 'Frederik', 'Anna', 'Jonas', 'Laura', 'Mathias', 'Ida', 'Oliver', 'Clara'];
  const lastNames = ['Nielsen', 'Jensen', 'Hansen', 'Pedersen', 'Andersen', 'Christensen', 'Larsen', 'Sørensen',
                    'Rasmussen', 'Jørgensen', 'Madsen', 'Kristensen', 'Olsen', 'Thomsen', 'Poulsen'];
  
  const name = `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${lastNames[Math.floor(Math.random() * lastNames.length)]}`;
  
  // Determine member type based on team name
  let memberType: typeof MEMBER_TYPES[number];
  if (teamName.includes('U')) {
    memberType = 'youth';
  } else if (teamName.includes('Senior')) {
    memberType = 'senior';
  } else if (teamName.includes('Veteran') || teamName.includes('Old Boys')) {
    memberType = Math.random() > 0.3 ? 'senior' : 'passive';
  } else {
    memberType = MEMBER_TYPES[Math.floor(Math.random() * MEMBER_TYPES.length)];
  }

  const status = MEMBER_STATUSES[Math.floor(Math.random() * MEMBER_STATUSES.length)];
  const paymentStatus = PAYMENT_STATUSES[Math.floor(Math.random() * PAYMENT_STATUSES.length)];
  
  const memberSince = new Date();
  memberSince.setMonth(memberSince.getMonth() - Math.floor(Math.random() * 24));
  
  const birthDate = new Date();
  if (teamName.includes('U13')) {
    birthDate.setFullYear(birthDate.getFullYear() - (12 + Math.floor(Math.random() * 2)));
  } else if (teamName.includes('U15')) {
    birthDate.setFullYear(birthDate.getFullYear() - (14 + Math.floor(Math.random() * 2)));
  } else if (teamName.includes('U17')) {
    birthDate.setFullYear(birthDate.getFullYear() - (16 + Math.floor(Math.random() * 2)));
  } else if (teamName.includes('U19')) {
    birthDate.setFullYear(birthDate.getFullYear() - (18 + Math.floor(Math.random() * 2)));
  } else if (teamName.includes('Senior')) {
    birthDate.setFullYear(birthDate.getFullYear() - (20 + Math.floor(Math.random() * 15)));
  } else {
    birthDate.setFullYear(birthDate.getFullYear() - (35 + Math.floor(Math.random() * 25)));
  }

  const lastPaymentDate = new Date();
  lastPaymentDate.setMonth(lastPaymentDate.getMonth() - Math.floor(Math.random() * 6));

  const cities = ['København', 'Aarhus', 'Odense', 'Aalborg', 'Esbjerg'];
  const streets = ['Hovedgaden', 'Søvej', 'Bakkevej', 'Strandvejen', 'Skolegade'];

  return {
    name,
    email: `${name.toLowerCase().replace(' ', '.')}@example.com`,
    phone: `+45 ${Math.floor(10000000 + Math.random() * 90000000)}`,
    address: `${Math.floor(Math.random() * 100)} ${streets[Math.floor(Math.random() * streets.length)]}, ${1000 + Math.floor(Math.random() * 8000)} ${cities[Math.floor(Math.random() * cities.length)]}`,
    birthDate,
    memberType,
    memberSince,
    status,
    teamId,
    departmentId,
    notes: Math.random() > 0.7 ? 'Særlige bemærkninger for dette medlem' : undefined,
    paymentStatus,
    lastPaymentDate,
    membershipFee: memberType === 'youth' ? 600 : memberType === 'passive' ? 200 : 800
  };
};

export const generateTestData = async (clubEmail: string) => {
  try {
    // Generate departments
    const departmentIds = [];
    for (const dept of DEPARTMENTS) {
      const id = await db.departments.add({
        name: dept.name,
        budget: dept.budget,
        expenses: dept.expenses,
        income: dept.income,
        description: dept.description,
        createdAt: new Date(),
        clubId: clubEmail
      });
      departmentIds.push({ id, ...dept });
    }

    // Generate teams for each department
    for (const dept of departmentIds) {
      const appropriateTeams = TEAM_NAMES.filter(name => 
        dept.teamTypes.some(type => name.includes(type))
      );

      const numTeams = Math.min(appropriateTeams.length, 2 + Math.floor(Math.random() * 3));
      const selectedTeams = appropriateTeams
        .sort(() => Math.random() - 0.5)
        .slice(0, numTeams);

      for (const teamName of selectedTeams) {
        const teamId = await db.teams.add({
          name: teamName,
          league: `Serie ${Math.floor(Math.random() * 4) + 1}`,
          founded: 2000 + Math.floor(Math.random() * 23),
          logo: `https://picsum.photos/200?random=${Math.random()}`,
          departmentId: dept.id
        });

        // Generate members for this team
        const numMembers = Math.floor(
          dept.expectedMembers.min + 
          Math.random() * (dept.expectedMembers.max - dept.expectedMembers.min)
        ) / numTeams;

        for (let i = 0; i < numMembers; i++) {
          const memberData = generateRandomMember(teamId, dept.id, teamName);
          await db.members.add(memberData);
        }

        // Generate training sessions for this team
        const numSessions = 2 + Math.floor(Math.random() * 2);
        const usedDays = new Set();

        for (let i = 0; i < numSessions; i++) {
          let dayOfWeek;
          do {
            dayOfWeek = 1 + Math.floor(Math.random() * 5);
          } while (usedDays.has(dayOfWeek));
          usedDays.add(dayOfWeek);

          const startHour = 16 + Math.floor(Math.random() * 4);
          const startTime = `${startHour.toString().padStart(2, '0')}:00`;
          const endTime = `${(startHour + 1).toString().padStart(2, '0')}:30`;
          
          await db.trainingSessions.add({
            teamId,
            dayOfWeek,
            startTime,
            endTime,
            location: LOCATIONS[Math.floor(Math.random() * LOCATIONS.length)]
          });
        }
      }
    }

    return true;
  } catch (error) {
    console.error('Error generating test data:', error);
    return false;
  }
};

export const clearAllData = async () => {
  try {
    await db.departments.clear();
    await db.teams.clear();
    await db.players.clear();
    await db.matches.clear();
    await db.members.clear();
    await db.trainingSessions.clear();
    return true;
  } catch (error) {
    console.error('Error clearing data:', error);
    return false;
  }
};