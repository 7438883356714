import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, Clock } from 'lucide-react';

export default function TopBar() {
  return (
    <div className="hidden md:block bg-[#1E293B] text-gray-300 py-2.5 text-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-8">
            <span className="flex items-center hover:text-white transition-colors duration-200">
              <Phone className="h-4 w-4 mr-2" />
              +45 70 12 13 14
            </span>
            <span className="flex items-center hover:text-white transition-colors duration-200">
              <Mail className="h-4 w-4 mr-2" />
              kontakt@fodboldsystem.dk
            </span>
            <span className="flex items-center hover:text-white transition-colors duration-200">
              <Clock className="h-4 w-4 mr-2" />
              Man-Fre: 09:00-17:00
            </span>
          </div>
          <div className="flex items-center space-x-8">
            <Link to="/support" className="hover:text-white transition-colors duration-200">
              Support
            </Link>
            <Link to="/login" className="hover:text-white transition-colors duration-200">
              Log ind
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}