import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../db/database';
import { generateTestData } from '../utils/generateTestData';

interface AuthContextType {
  isAuthenticated: boolean;
  isAdmin: boolean;
  user: { email: string; clubName?: string; isAdmin?: boolean } | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('auth_token') !== null;
  });
  const [isAdmin, setIsAdmin] = useState(() => {
    return localStorage.getItem('is_admin') === 'true';
  });
  const [user, setUser] = useState<{ email: string; clubName?: string; isAdmin?: boolean } | null>(() => {
    const email = localStorage.getItem('user_email');
    const isAdmin = localStorage.getItem('is_admin') === 'true';
    return email ? { email, isAdmin } : null;
  });

  useEffect(() => {
    const initAuth = async () => {
      const token = localStorage.getItem('auth_token');
      const email = localStorage.getItem('user_email');
      const adminStatus = localStorage.getItem('is_admin');
      
      if (token && email) {
        try {
          const user = await db.users.where('email').equals(email).first();
          if (user) {
            setIsAuthenticated(true);
            setIsAdmin(adminStatus === 'true');
            setUser({ 
              email, 
              clubName: user.clubName,
              isAdmin: adminStatus === 'true' 
            });
          }
        } catch (error) {
          console.error('Error initializing auth:', error);
          localStorage.removeItem('auth_token');
          localStorage.removeItem('user_email');
          localStorage.removeItem('is_admin');
        }
      }
    };

    initAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const user = await db.users.where('email').equals(email.toLowerCase()).first();
      
      if (!user) {
        throw new Error('Forkert email eller adgangskode');
      }

      if (user.password !== password) {
        throw new Error('Forkert email eller adgangskode');
      }

      const token = user.isAdmin ? 'admin_token' : 'user_token';

      localStorage.setItem('auth_token', token);
      localStorage.setItem('user_email', email.toLowerCase());
      localStorage.setItem('is_admin', user.isAdmin ? 'true' : 'false');
      
      setIsAuthenticated(true);
      setIsAdmin(!!user.isAdmin);
      setUser({ 
        email, 
        clubName: user.clubName,
        isAdmin: user.isAdmin 
      });

      // Generate test data for new users
      if (!user.isAdmin) {
        await generateTestData(email.toLowerCase());
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_email');
    localStorage.removeItem('is_admin');
    setIsAuthenticated(false);
    setIsAdmin(false);
    setUser(null);
  };

  const value = {
    isAuthenticated,
    isAdmin,
    user,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}