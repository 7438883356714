import React from 'react';
import { Users, BarChart3, Calendar, MessageCircle, Database, Settings2 } from 'lucide-react';
import FeatureCard from './FeatureCard';
import VideoAnimation from './VideoAnimation';

const features = [
  {
    icon: Users,
    title: "Medlemsstyring",
    description: "Effektiv håndtering af medlemmer, kontingenter og kommunikation."
  },
  {
    icon: BarChart3,
    title: "Økonomi",
    description: "Komplet overblik over klubbens økonomi med automatisk bogføring."
  },
  {
    icon: Calendar,
    title: "Aktiviteter",
    description: "Planlæg og administrer træning, kampe og events effektivt."
  },
  {
    icon: MessageCircle,
    title: "Kommunikation",
    description: "Direkte kommunikation mellem trænere, spillere og forældre."
  },
  {
    icon: Database,
    title: "Data & Rapporter",
    description: "Detaljerede rapporter og analyser af klubbens aktiviteter."
  },
  {
    icon: Settings2,
    title: "Integration",
    description: "Seamless integration med DBU systemer og andre relevante platforme."
  }
];

export default function Features() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Alt samlet ét sted
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Fodboldsystem giver dig alle de værktøjer, du har brug for til professionel klubadministration
          </p>
          
          {/* Video Animation */}
          <VideoAnimation />
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}