import React from 'react';
import { useLocation } from 'react-router-dom';
import { CreditCard, Download, Clock } from 'lucide-react';
import { format } from 'date-fns';

export default function Subscription() {
  const location = useLocation();
  const selectedPlan = location.state?.selectedPlan;

  // Mock invoice data
  const invoices = [
    {
      id: 1,
      date: new Date(),
      amount: selectedPlan?.price || 0,
      status: 'Betalt',
      period: 'Mar 2024'
    },
    {
      id: 2,
      date: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
      amount: selectedPlan?.price || 0,
      status: 'Betalt',
      period: 'Feb 2024'
    }
  ];

  return (
    <div className="space-y-6">
      {/* Current Subscription */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Dit Abonnement</h2>
        <div className="flex items-start justify-between">
          <div>
            <p className="text-lg font-medium text-gray-900">{selectedPlan?.title || 'Standard'} Plan</p>
            <p className="text-gray-600">{selectedPlan?.price || 499} kr/md</p>
            <p className="text-sm text-gray-500 mt-2">Næste fakturering: {format(new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), 'dd/MM/yyyy')}</p>
          </div>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition">
            Skift plan
          </button>
        </div>
      </div>

      {/* Payment Method */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Betalingsmetode</h2>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CreditCard className="h-8 w-8 text-gray-400 mr-3" />
            <div>
              <p className="font-medium text-gray-900">•••• •••• •••• 4242</p>
              <p className="text-sm text-gray-500">Udløber 12/25</p>
            </div>
          </div>
          <button className="text-blue-600 hover:text-blue-700 font-medium">
            Rediger
          </button>
        </div>
      </div>

      {/* Billing History */}
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Fakturaoversigt</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Dato
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Periode
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Beløb
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Handling
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(invoice.date, 'dd/MM/yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {invoice.period}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {invoice.amount} kr
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {invoice.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button className="text-blue-600 hover:text-blue-700 flex items-center justify-end ml-auto">
                      <Download className="h-4 w-4 mr-1" />
                      PDF
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}