import Dexie, { Table } from 'dexie';

export interface User {
  id?: number;
  email: string;
  name: string;
  clubName: string;
  createdAt: Date;
  password: string;
  isAdmin?: boolean;
}

export interface Department {
  id?: number;
  name: string;
  budget: number;
  expenses: number;
  income: number;
  description: string;
  createdAt: Date;
  clubId: string;
}

export interface Team {
  id?: number;
  name: string;
  league: string;
  founded: number;
  logo?: string;
  departmentId?: number;
}

export interface Player {
  id?: number;
  name: string;
  position: string;
  number: number;
  team: string;
  birthDate: Date;
  image?: string;
  departmentId?: number;
}

export interface Match {
  id?: number;
  homeTeam: string;
  awayTeam: string;
  date: Date;
  venue: string;
  status: 'scheduled' | 'live' | 'finished';
  homeScore?: number;
  awayScore?: number;
  departmentId?: number;
}

export interface Member {
  id?: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  birthDate: Date;
  memberType: 'active' | 'passive' | 'youth' | 'senior';
  memberSince: Date;
  status: 'active' | 'inactive';
  teamId?: number;
  departmentId?: number;
  notes?: string;
  paymentStatus?: 'paid' | 'pending' | 'overdue';
  lastPaymentDate?: Date;
  membershipFee?: number;
}

export interface PaymentRecord {
  id?: number;
  memberId: number;
  amount: number;
  date: Date;
  type: 'membership' | 'training' | 'other';
  status: 'completed' | 'pending' | 'failed';
  description?: string;
}

export interface TrainingSession {
  id?: number;
  teamId: number;
  dayOfWeek: number;
  startTime: string;
  endTime: string;
  location: string;
}

class FootballDatabase extends Dexie {
  users!: Table<User>;
  departments!: Table<Department>;
  teams!: Table<Team>;
  players!: Table<Player>;
  matches!: Table<Match>;
  members!: Table<Member>;
  payments!: Table<PaymentRecord>;
  trainingSessions!: Table<TrainingSession>;

  constructor() {
    super('FootballDB');

    // Define schema with version 2
    this.version(2).stores({
      users: '++id, email',
      departments: '++id, clubId',
      teams: '++id, name, departmentId',
      players: '++id, name, team, departmentId',
      matches: '++id, homeTeam, awayTeam, date, departmentId',
      members: '++id, name, email, memberType, status, departmentId, teamId',
      payments: '++id, memberId, date',
      trainingSessions: '++id, teamId, dayOfWeek'
    });

    this.on('ready', () => {
      this.initializeAdmin().catch(console.error);
    });
  }

  private async initializeAdmin() {
    try {
      const adminExists = await this.users.where('email').equals('mecci@live.dk').count();
      if (adminExists === 0) {
        await this.users.add({
          email: 'mecci@live.dk',
          name: 'Admin',
          clubName: 'System Admin',
          createdAt: new Date(),
          password: 'lars12',
          isAdmin: true
        });
        console.log('Admin user created successfully');
      }
    } catch (error) {
      console.error('Error initializing admin:', error);
    }
  }

  async getDepartmentsByClub(clubId: string) {
    return await this.departments.where('clubId').equals(clubId).toArray();
  }

  async getMemberStats() {
    const members = await this.members.toArray();
    return {
      total: members.length,
      active: members.filter(m => m.status === 'active').length,
      youth: members.filter(m => m.memberType === 'youth').length,
      senior: members.filter(m => m.memberType === 'senior').length
    };
  }

  async getFinancialSummary() {
    const members = await this.members.toArray();
    const payments = await this.payments.toArray();

    const totalRevenue = payments.reduce((sum, payment) => sum + payment.amount, 0);
    const paidMembers = members.filter(m => m.paymentStatus === 'paid').length;
    const overdueMembers = members.filter(m => m.paymentStatus === 'overdue').length;
    const averageMembershipFee = members.reduce((sum, m) => sum + (m.membershipFee || 0), 0) / members.length || 0;

    return {
      totalRevenue,
      paidMembers,
      overdueMembers,
      averageMembershipFee
    };
  }
}

export const db = new FootballDatabase();