import React, { useState } from 'react';
import { useLiveQuery } from 'dexie-react-hooks';
import { 
  Users, CreditCard, Plus, PencilLine, Trash2,
  Trophy, Calendar, TrendingUp, Building2
} from 'lucide-react';
import { db } from '../db/database';
import { useAuth } from '../context/AuthContext';
import DashboardLayout from '../components/DashboardLayout';
import type { Department } from '../db/database';

export default function Dashboard() {
  const { user } = useAuth();
  const [showAddModal, setShowAddModal] = useState(false);
  const [editingDepartment, setEditingDepartment] = useState<Department | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);

  const departments = useLiveQuery(
    () => user ? db.getDepartmentsByClub(user.email) : []
  );

  const memberStats = useLiveQuery(() => db.getMemberStats());

  const handleDeleteDepartment = async (id: number) => {
    if (window.confirm('Er du sikker på at du vil slette denne afdeling?')) {
      await db.departments.delete(id);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        {/* Welcome Section */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl shadow-xl p-6 sm:p-8 text-white mb-8">
          <h1 className="text-2xl sm:text-3xl font-bold mb-2">Velkommen tilbage, {user?.clubName}</h1>
          <p className="text-blue-100">Her er dit overblik for i dag</p>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-8">
          <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 border-t-4 border-blue-500">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500">Antal Medlemmer</p>
                <p className="text-xl sm:text-2xl font-bold">{memberStats?.total || 0}</p>
              </div>
              <Users className="w-8 sm:w-10 h-8 sm:h-10 text-blue-600" />
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 border-t-4 border-green-500">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500">Aktive Medlemmer</p>
                <p className="text-xl sm:text-2xl font-bold">{memberStats?.active || 0}</p>
              </div>
              <Trophy className="w-8 sm:w-10 h-8 sm:h-10 text-green-600" />
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 border-t-4 border-purple-500">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500">Afdelinger</p>
                <p className="text-xl sm:text-2xl font-bold">{departments?.length || 0}</p>
              </div>
              <Building2 className="w-8 sm:w-10 h-8 sm:h-10 text-purple-600" />
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg p-4 sm:p-6 border-t-4 border-yellow-500">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-gray-500">Total Budget</p>
                <p className="text-xl sm:text-2xl font-bold">
                  {departments?.reduce((sum, dept) => sum + dept.budget, 0).toLocaleString()} kr
                </p>
              </div>
              <CreditCard className="w-8 sm:w-10 h-8 sm:h-10 text-yellow-600" />
            </div>
          </div>
        </div>

        {/* Departments Section */}
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
            <div>
              <h2 className="text-xl font-bold text-gray-900">Afdelinger</h2>
              <p className="text-sm text-gray-500">Administrer klubbens afdelinger</p>
            </div>
            <button
              onClick={() => setShowAddModal(true)}
              className="w-full sm:w-auto bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-700 transition"
            >
              <Plus className="w-5 h-5" />
              Tilføj afdeling
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {departments?.map((department) => (
              <div
                key={department.id}
                className="bg-gray-50 rounded-xl p-6 hover:bg-gray-100 transition cursor-pointer"
                onClick={() => setSelectedDepartment(department)}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="bg-blue-100 p-3 rounded-lg">
                    <Building2 className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingDepartment(department);
                        setShowAddModal(true);
                      }}
                      className="p-2 hover:bg-gray-200 rounded-lg transition"
                    >
                      <PencilLine className="h-4 w-4 text-gray-500" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        department.id && handleDeleteDepartment(department.id);
                      }}
                      className="p-2 hover:bg-red-100 rounded-lg transition"
                    >
                      <Trash2 className="h-4 w-4 text-red-500" />
                    </button>
                  </div>
                </div>

                <h3 className="text-lg font-semibold text-gray-900 mb-2">{department.name}</h3>
                <p className="text-gray-600 text-sm mb-4">{department.description}</p>

                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Budget</span>
                    <span className="font-medium">{department.budget.toLocaleString()} kr</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Udgifter</span>
                    <span className="font-medium text-red-600">
                      {department.expenses.toLocaleString()} kr
                    </span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Indtægter</span>
                    <span className="font-medium text-green-600">
                      {department.income.toLocaleString()} kr
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {(!departments || departments.length === 0) && (
            <div className="text-center py-12">
              <Building2 className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">Ingen afdelinger</h3>
              <p className="mt-1 text-sm text-gray-500">
                Kom i gang ved at oprette din første afdeling.
              </p>
            </div>
          )}
        </div>

        {/* Add/Edit Department Modal */}
        {showAddModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-md w-full p-6">
              <h2 className="text-xl font-bold mb-4">
                {editingDepartment ? 'Rediger afdeling' : 'Tilføj ny afdeling'}
              </h2>
              <form onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData(e.currentTarget);

                const departmentData = {
                  name: formData.get('name') as string,
                  budget: Number(formData.get('budget')),
                  expenses: editingDepartment?.expenses || 0,
                  income: editingDepartment?.income || 0,
                  description: formData.get('description') as string,
                  createdAt: new Date(),
                  clubId: user!.email
                };

                if (editingDepartment?.id) {
                  await db.departments.update(editingDepartment.id, departmentData);
                } else {
                  await db.departments.add(departmentData);
                }

                setShowAddModal(false);
                setEditingDepartment(null);
              }} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Navn
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    defaultValue={editingDepartment?.name}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Budget
                  </label>
                  <input
                    required
                    type="number"
                    name="budget"
                    min="0"
                    step="1000"
                    defaultValue={editingDepartment?.budget}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Beskrivelse
                  </label>
                  <textarea
                    name="description"
                    rows={3}
                    defaultValue={editingDepartment?.description}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddModal(false);
                      setEditingDepartment(null);
                    }}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition"
                  >
                    Annuller
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                  >
                    {editingDepartment ? 'Gem ændringer' : 'Tilføj afdeling'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Department Details Modal */}
        {selectedDepartment && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto">
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900">{selectedDepartment.name}</h2>
                    <p className="text-gray-600">{selectedDepartment.description}</p>
                  </div>
                  <button
                    onClick={() => setSelectedDepartment(null)}
                    className="p-2 hover:bg-gray-100 rounded-full"
                  >
                    <span className="sr-only">Luk</span>
                    ×
                  </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                  <div className="bg-green-50 p-4 rounded-lg">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-600">Indtægter</span>
                      <TrendingUp className="h-5 w-5 text-green-600" />
                    </div>
                    <p className="text-2xl font-bold text-green-600">
                      {selectedDepartment.income.toLocaleString()} kr
                    </p>
                  </div>

                  <div className="bg-red-50 p-4 rounded-lg">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-600">Udgifter</span>
                      <TrendingUp className="h-5 w-5 text-red-600" />
                    </div>
                    <p className="text-2xl font-bold text-red-600">
                      {selectedDepartment.expenses.toLocaleString()} kr
                    </p>
                  </div>

                  <div className="bg-blue-50 p-4 rounded-lg">
                    <div className="flex items-center justify-between mb-2">
                      <span className="text-sm text-gray-600">Balance</span>
                      <CreditCard className="h-5 w-5 text-blue-600" />
                    </div>
                    <p className="text-2xl font-bold text-blue-600">
                      {(selectedDepartment.income - selectedDepartment.expenses).toLocaleString()} kr
                    </p>
                  </div>
                </div>

                <div className="bg-gray-50 p-4 rounded-lg mb-8">
                  <div className="flex items-center justify-between mb-2">
                    <span className="font-medium">Budget Status</span>
                    <span className="text-sm text-gray-600">
                      {Math.round((selectedDepartment.expenses / selectedDepartment.budget) * 100)}% brugt
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${(selectedDepartment.expenses / selectedDepartment.budget) * 100}%` }}
                    ></div>
                  </div>
                  <div className="mt-2 text-sm text-gray-600">
                    Resterende budget: {(selectedDepartment.budget - selectedDepartment.expenses).toLocaleString()} kr
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}