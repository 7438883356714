import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

export default function CallToAction() {
  return (
    <section className="py-20 bg-blue-900">
      <div className="max-w-4xl mx-auto px-4 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
          Klar til at modernisere din klub?
        </h2>
        <p className="text-xl text-blue-100 mb-8">
          Start gratis i dag og oplev forskellen med Fodboldsystem
        </p>
        <Link 
          to="/register" 
          className="inline-flex items-center px-8 py-3 border border-transparent text-lg font-medium rounded-md text-blue-900 bg-white hover:bg-blue-50 transition-colors"
        >
          Kom i gang nu <ArrowRight className="ml-2 h-5 w-5" />
        </Link>
      </div>
    </section>
  );
}