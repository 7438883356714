import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Trophy, Menu, Info, ChevronDown, Heart, Users, Phone } from 'lucide-react';

export default function MainNav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSectionClick = (sectionId: string) => {
    setIsDropdownOpen(false);
    setIsMenuOpen(false);

    if (location.pathname !== '/about') {
      navigate('/about');
      // Wait for navigation to complete before scrolling
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-blue-900 text-white z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <Trophy className="h-6 w-6 mr-2" />
              <span className="font-bold text-xl">Fodboldsystem</span>
            </Link>
          </div>

          <div className="flex items-center lg:hidden">
            <button
              className="inline-flex items-center justify-center p-3 rounded-md text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={isMenuOpen}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Åbn menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex items-center space-x-6">
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center px-3 py-2 rounded-md hover:bg-blue-800 hover:text-white"
              >
                <Info className="h-5 w-5 mr-1" />
                <span>Om Os</span>
                <ChevronDown className={`ml-1 h-4 w-4 transform transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
              </button>

              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg py-1 text-gray-700">
                  <button
                    onClick={() => handleSectionClick('mission')}
                    className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    <Trophy className="h-4 w-4 mr-2 text-blue-600" />
                    Vores Mission
                  </button>
                  <button
                    onClick={() => handleSectionClick('values')}
                    className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    <Heart className="h-4 w-4 mr-2 text-red-500" />
                    Vores Værdier
                  </button>
                  <button
                    onClick={() => handleSectionClick('team')}
                    className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    <Users className="h-4 w-4 mr-2 text-green-600" />
                    Holdet Bag
                  </button>
                  <button
                    onClick={() => handleSectionClick('contact')}
                    className="flex items-center w-full px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    <Phone className="h-4 w-4 mr-2 text-purple-600" />
                    Kontakt
                  </button>
                </div>
              )}
            </div>
            <Link
              to="/register"
              className="bg-white text-blue-600 px-4 py-2 rounded-md font-semibold hover:bg-blue-50 transition-colors"
            >
              Opret Forening
            </Link>
            <Link
              to="/login"
              className="text-white hover:text-blue-100 px-4 py-2 font-semibold transition-colors"
            >
              Log ind
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <button
              onClick={() => handleSectionClick('mission')}
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
            >
              <Trophy className="h-4 w-4 mr-2" />
              Vores Mission
            </button>
            <button
              onClick={() => handleSectionClick('values')}
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
            >
              <Heart className="h-4 w-4 mr-2" />
              Vores Værdier
            </button>
            <button
              onClick={() => handleSectionClick('team')}
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
            >
              <Users className="h-4 w-4 mr-2" />
              Holdet Bag
            </button>
            <button
              onClick={() => handleSectionClick('contact')}
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
            >
              <Phone className="h-4 w-4 mr-2" />
              Kontakt
            </button>
            <Link
              to="/register"
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
              onClick={() => setIsMenuOpen(false)}
            >
              Opret Forening
            </Link>
            <Link
              to="/login"
              className="flex items-center w-full px-3 py-2 rounded-md text-base font-medium text-white hover:bg-blue-800"
              onClick={() => setIsMenuOpen(false)}
            >
              Log ind
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
}