import React from 'react';
import '../styles/animations.css';

export default function VideoAnimation() {
  return (
    <div className="relative w-full max-w-4xl mx-auto mt-12 mb-20 aspect-video">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-blue-800 to-blue-700 rounded-2xl shadow-2xl overflow-hidden">
        {/* Animated Background Elements */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20 animate-grid"></div>
          
          {/* Floating Circles */}
          <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-blue-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-slow"></div>
          <div className="absolute bottom-1/4 right-1/4 w-32 h-32 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-float-delayed"></div>
          
          {/* Animated Lines */}
          <svg className="absolute inset-0 w-full h-full" viewBox="0 0 100 100">
            <path
              d="M20,50 Q50,20 80,50"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="0.5"
              fill="none"
              className="animate-draw-path"
            />
            <path
              d="M20,50 Q50,80 80,50"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="0.5"
              fill="none"
              className="animate-draw-path-delayed"
            />
          </svg>
        </div>

        {/* Main Animation Content */}
        <div className="relative z-10 flex items-center justify-center h-full">
          <div className="text-center">
            {/* Animated Icons */}
            <div className="flex justify-center space-x-8 mb-8">
              {/* User Management Icon */}
              <div className="animation-card bg-white/10 p-4 rounded-xl backdrop-blur-sm">
                <svg className="w-12 h-12 text-white animate-pulse-slow" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                  <circle cx="12" cy="7" r="4" />
                </svg>
              </div>

              {/* Database Icon */}
              <div className="animation-card bg-white/10 p-4 rounded-xl backdrop-blur-sm">
                <svg className="w-12 h-12 text-white animate-pulse-slow animation-delay-200" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M12 3c5.523 0 10 1.79 10 4s-4.477 4-10 4-10-1.79-10-4 4.477-4 10-4z" />
                  <path d="M2 7c0 2.21 4.477 4 10 4s10-1.79 10-4" />
                  <path d="M2 11c0 2.21 4.477 4 10 4s10-1.79 10-4" />
                  <path d="M2 15c0 2.21 4.477 4 10 4s10-1.79 10-4" />
                </svg>
              </div>

              {/* Automation Icon */}
              <div className="animation-card bg-white/10 p-4 rounded-xl backdrop-blur-sm">
                <svg className="w-12 h-12 text-white animate-pulse-slow animation-delay-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <circle cx="12" cy="12" r="3" />
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" />
                </svg>
              </div>
            </div>

            {/* Animated Text */}
            <h3 className="text-2xl font-bold text-white mb-4 animate-fade-in">
              Automatiseret Medlemshåndtering
            </h3>
            <p className="text-blue-100 max-w-md mx-auto animate-fade-in animation-delay-200">
              Fra indmeldelse til kontingentbetaling - alt samlet i ét system
            </p>

            {/* Animated Progress Bars */}
            <div className="mt-8 space-y-3 max-w-sm mx-auto">
              <div className="bg-white/10 rounded-full h-2 overflow-hidden">
                <div className="bg-blue-400 h-full rounded-full animate-progress w-3/4"></div>
              </div>
              <div className="bg-white/10 rounded-full h-2 overflow-hidden">
                <div className="bg-purple-400 h-full rounded-full animate-progress-delayed w-5/6"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Floating Particles */}
        <div className="particle-container">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="particle"
              style={{
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 5}s`,
                animationDuration: `${5 + Math.random() * 5}s`
              }}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}